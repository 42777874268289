import React from 'react'
import classNames from 'classnames'
import './index.less'
import { Anchor } from 'antd'
import { imgUrl } from '../../utils/staticUrl'
import { useHistory, useLocation } from 'react-router-dom'
const headerMenu = [
  {
    key: 'part-1',
    href: '#part-1',
    title: '我们的业务',
  },
  {
    key: 'part-2',
    href: '#part-2',
    title: '版权保护预警',
  },
  {
    key: 'part-3',
    href: '#part-3',
    title: '预警价值评估',
  },
  {
    key: 'part-4',
    href: '#part-4',
    title: '监测案例',
  },
  {
    key: 'part-5',
    href: '#part-5',
    title: '新闻中心',
  },
]
const backTop = [
  {
    key: 'backTop',
    href: '#backTop',
    title: (
      <>
        <img src={imgUrl('home/pic_8.png')} />
      </>
    ),
  },
]
const PassHeader = ({ className }) => {
  const pathname = useLocation()
  const history = useHistory()
  const anchorClict = (e) => {
    e.preventDefault()
  }
  const handleBackHome = (item) => {
    if (item.key != 'part-5') {
      history.push('/protect')
    }
  }
  return (
    <div id={classNames('pass_header', className)}>
      <div className="header_content">
        <div className="header_left">
          <div className="logo"></div>
        </div>
        <div className="header_right">
          {pathname.pathname == '/protect' ? (
            <Anchor
              items={headerMenu}
              getCurrentAnchor={'#part-4'}
              direction="horizontal"
              targetOffset={120}
              affix={false}
              onClick={anchorClict}
            />
          ) : (
            <>
              {headerMenu.map((item, index) => (
                <div
                  className="header_menu"
                  key={index}
                  onClick={() => {
                    handleBackHome(item)
                  }}
                >
                  {item.title}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="backTop">
        <Anchor items={backTop} onClick={anchorClict} />
      </div>
    </div>
  )
}

export default PassHeader
