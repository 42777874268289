import React, { useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import { imgUrl } from '../../utils/staticUrl'
import './index.less'
const qrCode = [
  {
    img: '',
    title: '版权盒子',
    name: '官方微博',
  },
  {
    img: '',
    title: '版权盒子',
    name: '官方微信工作号',
  },
]
const PassFooter = () => {
  const { lng } = useAppSelector((store) => store.global)
  useEffect(() => {}, [lng])
  return (
    <div id="pass_footer">
      <div className="footer_content">
        <div className="logo">
          <img src={imgUrl('aacCommon/logo.png')} />
        </div>
        <div className="footer_message">
          <div className="footer_left">
            <div className="left">
              <img src={imgUrl('home/ico_21.png')} alt="" />
              <span>{'北京市朝阳区惠新东街11号1号楼19层A-19号'}</span>
            </div>
            <div className="left">
              <img src={imgUrl('home/ico_22.png')} alt="" />
              <span>{'电话：139 0116 8260 '}</span>
              <img src={imgUrl('home/ico_23.png')} alt="" />
              <span>{'邮箱：gfanx@gfanx.com'}</span>
            </div>
            <div className="left">
              <img src={imgUrl('home/ico_22.png')} alt="" />
              <span>{'Copyright@ 2020-2022 Meta-World 版权所有  京ICP备2022010222号'}</span>
            </div>
          </div>
          <div className="footer_right">
            {qrCode &&
              qrCode.map((item, index) => (
                <div className="qrCode" key={index}>
                  <div className="code_left">{item.img && <img src={item.img} alt="" />}</div>
                  <div className="code_right">
                    <p>{item.title}</p>
                    <p>{item.name}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PassFooter
